$header-nav-height: 60px;
$bulletin-height: calc(100vh - $header-nav-height);
$compact-map-height: 300px;
$modal-height-md: 59px;
$modal-height-sm: 104px;
// Layout
.create-bulletin {
  display: flex;
  align-items: stretch;
  height: $bulletin-height;
  background: white;
}

.create-bulletin__sidebar,
.create-bulletin__region-form {
  height: $bulletin-height;
  overflow-y: scroll;
  overflow-x: hidden;

  /* Hide scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
}

.create-bulletin__map-container,
.create-bulletin__map {
  height: $bulletin-height;
}

.create-bulletin__map-container--am-pm .create-bulletin__map {
  height: calc($bulletin-height / 2);
}

// Sidebar
$sidebar-width: 256px;
$sidebar-padding: $space-4;
$sidebar-border: 1px solid $gray-300;

.create-bulletin__sidebar {
  position: relative;
  width: $sidebar-width;
  flex: 0 0 $sidebar-width;
  border-right: $sidebar-border;
  transition:
    flex-basis 0.5s ease,
    transform 0.5s ease;

  &.hidden {
    overflow: hidden;
    flex-basis: 0;
    transform: translateX(-100%);
  }
}

.create-bulletin__sidebar-header {
  padding: $sidebar-padding;
  padding-top: $sidebar-padding * 1.5;
  background: $white;
}

.create-bulletin__sidebar-close {
  position: absolute;
  top: calc($sidebar-padding / 2);
  right: $sidebar-padding;
}

.create-bulletin__sidebar-close-on-map {
  position: absolute;
  top: 70px;
  left: 10px;
  z-index: 1020;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 34px;
  height: 33px;
  font-size: 22px;

  background-color: $white;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid #ccc;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  &:hover {
    background-color: #f4f4f4;
  }
}

.create-bulletin__date {
  display: flex;
  justify-content: space-between;
  margin-bottom: $sidebar-padding;
}

.create-bulletin__current-date {
  margin-bottom: 0;
}

.create-bulleting__days-nav {
  margin-top: auto;
}

.create-bulletin__action-buttons {
  margin: $space-1 0 $space-1 0;
  width: 100%;
}

.create-bulletin__action-buttons--small {
  margin: $space-1 0 $space-1 0;
}

// Status
.create-bulletin__status-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  cursor: pointer;

  i {
    display: none;
  }

  &:hover i {
    display: block;
  }
}

.create-bulletin__bulletin-info {
  font-size: 12px;
  color: $secondary;
  margin: $space-4 0;
  display: flex;
  align-items: center;

  i {
    margin-right: $space-2;
  }
}

.create-bulletin__bulletin-warning {
  font-size: 12px;
  color: $secondary;
  margin: $space-4 0;
  display: flex;
  align-items: center;

  i {
    margin-right: $space-2;
  }
}

.create-bulletin__bulletin-alert {
  font-size: 12px;
  font-weight: 600;
  color: $danger;
  margin: $space-4 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  i.icon-left {
    margin-right: $space-2;
  }

  i.icon-right {
    margin-left: $space-2;
  }
}

.create-bulletin__bulletin-status {
  margin-top: $sidebar-padding;

  li {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: $space-2 0;
    border-top: $sidebar-border;
    border-bottom: $sidebar-border;
    margin-top: -1px;
  }
}

// Regions list

.create-bulletin__region-divider {
  padding: $space-2 $sidebar-padding;
  background: $gray-100;
  border-top: $sidebar-border;
  border-bottom: $sidebar-border;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.foreign {
  cursor: pointer;
}

.region-thumb__actions {
  //opacity: 0;
}

.region-thumb {
  padding-top: $sidebar-padding;
  padding-right: calc($sidebar-padding / 2);
  padding-bottom: $sidebar-padding;
  padding-left: $sidebar-padding;
  border-bottom: $sidebar-border;
  background-color: white;
  display: block;
  position: relative;
  color: $black;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &:hover,
  &.region-thumb--active {
    background: $primary-pale;
  }

  &:hover {
    .region-thumb__actions {
      opacity: 1;
    }
  }
}

.region-thumb__avalanche-info {
  display: flex;
  flex-flow: row wrap;
  gap: $space-1;
  align-items: flex-end;
}

.region-thumb__avalanche-problem-icon img {
  width: 20px;
  height: 20px;
  border: 1px solid $gray-400;
}

app-danger-rating-icon {
  width: 20px;
  height: 20px;
}

.grid-container {
  display: inline-grid;
  grid-template-columns: 10px 10px;
  grid-template-rows: 10px 10px;
  vertical-align: top;
}

.create-bulletin__default-layout {
  display: flex;
  align-items: stretch;
  height: $bulletin-height;
  width: 100%;
}

.create-bulletin__compact-layout {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  .create-bulletin__region-form {
    width: 100%;
    height: calc(100vh - $compact-map-height - $header-nav-height);
  }

  .create-bulletin__map-container,
  .create-bulletin__map {
    height: $compact-map-height;
  }

  .create-bulletin__map-container--am-pm {
    display: flex;
    flex-direction: row;
  }

  .create-bulletin__map-container--am-pm .create-bulletin__map {
    width: 50%;
  }
}

.create-bulletin__compact-layout--compare {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  .create-bulletin__map-container {
    order: -1;
    width: 100%;
  }

  .create-bulletin__region-form {
    width: 50%;
    min-width: 0;
  }
}

/* Scroll map on small screens */
@media (max-height: ($compact-map-height * 2)) {
  .create-bulletin__default-layout.create-bulletin__compact-layout {
    overflow-y: auto;
    height: 100vh;

    .create-bulletin__region-form {
      height: auto;
      overflow: visible;
    }

    .create-bulletin__map-container {
      width: 100%;
      overflow: visible;
      position: relative;
    }
  }
}

.create-bulletin__nobulletins {
  display: flex;
  align-items: center; /* Centers items on the cross-axis (typically vertical) */
  justify-content: center; /* Centers items on the main-axis (typically horizontal) */
  flex-direction: column; /* Stack the items vertically */
  margin: $space-4;
  padding: $space-6;
  background-color: $albina-blue-pale;
  color: $albina-blue-dark;
  text-align: center;
  font-size: 14px;

  .ph {
    font-size: 32px;
  }

  p {
    margin-top: $space-6;
  }
}

// Responsive layout
@include media-breakpoint-down(md) {
  .create-bulletin__sidebar {
    width: 100%;
  }

  .create-bulletin__region-form {
    min-width: 0 !important;
  }

  .create-bulletin--modal {
    height: calc(100vh - $header-nav-height - $modal-height-md);
    padding-top: $modal-height-md;
  }
}

@include media-breakpoint-down(sm) {
  .create-bulletin__region-form,
  .create-bulletin__compact-layout--compare .create-bulletin__region-form {
    width: 100%;
  }

  .create-bulletin--modal {
    height: calc(100vh - $header-nav-height - $modal-height-sm);
    padding-top: $modal-height-sm;
  }
}
